import React from "react"
import { Layout } from "@/layout"
import {
  Width,
  Box,
  SEO,
  Heading,
  Text,
  CTA,
  SmallWave,
} from "@/components/index"

export default function PrepFaq() {
  return (
    <Layout headerType="checkout" headerTheme="light" footerTheme="light">
      <SEO
        title="Prep Program FAQ"
        description="Common Questions for the Prep Program"
      />
      <Width
        minheight="100%"
        position="relative"
        zIndex="3"
        top="0"
        width="100%"
        pt={["12.5rem", null, null, "9.6rem"]}
        pb={["4.8rem", null, null, "0"]}
      >
        <Box
          height="100%"
          width={["100%", null, 10 / 12]}
          mx="auto"
          pt="6rem"
          pb="9rem"
        >
          <SmallWave display="block" mx="auto" mb="1.2rem" />

          <Heading level="1" textAlign="center" mb="2rem">
            Sonora Prep Program - FAQs
          </Heading>

          <Text textAlign="center" mb="1em">
            Sonora Prep is a concentrated preparation program in Guitar
            Fundamentals - students learn basic theory, open chords, barre
            chords, reading tab and chord sheets, and basic scales.
          </Text>
          <Text textAlign="center" mb="2em">
            Sonora Prep is designed to take participants with little-to-some
            guitar experience to intensive-ready through 6 weeks of study under
            the guidance of expert instructors. The program supports you by
            focusing on both your technical and personal development. You’ll
            learn to trust yourself as a guitarist by becoming comfortable with
            the core fundamentals
          </Text>
          <Heading level="2" textAlign="center" mb="2rem">
            About the Program
          </Heading>
          <Width
            display={["block", null, "flex", null]}
            justifyContent="space-between"
            mr="1em"
          >
            <Box
              width={["100%", null, 5 / 12, null]}
              textAlign={["center", null, "left", null]}
            >
              <Heading as="h4" level={3} color="amethyst">
                How much does Sonora Prep Program cost?
              </Heading>
              <Text level={2} mb="3.6rem">
                Sonora Prep costs $500, due upon registration.
              </Text>
              <Heading as="h4" level={3} color="amethyst">
                How long does the course last and where is it held?
              </Heading>
              <Text level={2} mb="3.6rem">
                The class runs 10 weeks long. The course is conducted 100% online
                via live video conferencing and gives you access to an online
                community, live staff support, and a cohort communication
                platform.
              </Text>
              <Heading as="h4" level={3} color="amethyst">
                What is covered in the prep program?
              </Heading>
              <Text level={2} mb="3.6rem">
                Sonora Prep is a concentrated preparation program in Guitar
                Fundamentals - students learn basic theory, open chords, right
                hand technique, barre chords, reading tabs/chord sheets, and
                basic scales. You will also learn 6 assigned songs.
              </Text>
              <Heading as="h4" level={3} color="amethyst">
                What are my time commitments?
              </Heading>
              <Text level={2} mb="3.6rem">
                Attendance and engagement are essential for your success. You
                will need to attend every class possible. Missing class is
                discouraged and you will be responsible for making up the work
                independently. Engaging with the staff during the time you are
                there about your personal schedule is important. Make sure you
                utilize our support, as it increases your likelihood of being
                fully prepared and being admitted into the core program.
              </Text>
              <Heading as="h4" level={3} color="amethyst">
                What is the refund policy for Sonora Prep?
              </Heading>
              <Text level={2} mb="3.6rem">
                If you withdraw from Sonora Prep{" "}
                <strong>
                  <em>within</em>
                </strong>{" "}
                the first week, you are eligible for a full refund. No refunds
                will be provided{" "}
                <strong>
                  <em>after</em>
                </strong>{" "}
                the first week has elapsed.
              </Text>
            </Box>
            <Box
              width={["100%", "80%", 6 / 12, null]}
              mx={["auto", null, 0, null]}
              textAlign={["center", null, "left", null]}
            >
              <Heading as="h4" level={3} color="amethyst">
                Who supports me through Sonora Prep?
              </Heading>
              <Text level={2} mb="3.6rem">
                A staff of experienced professionals are there to champion you
                through the prep material. Our head instructor is there to
                deliver the live classes and help you navigate through the ins
                and outs of the fundamentals on guitar using a variety of
                techniques. An admissions counselor is there to ensure that you
                have all of the necessary skills, keep you informed, and support
                your autonomy as you work through the material. Your cohort,
                made up of other hardworking and like-minded students, will
                provide additional support through your learning journey.
              </Text>
              <Heading as="h4" level={3} color="amethyst">
                What will I do during the class?
              </Heading>
              <Text level={2} mb="1rem">
                We will meet two times per week.{" "}
              </Text>
              <Text level={2} mb=".6rem">
                {" "}
                Monday 6pm PST: 1 hour lecture
              </Text>
              <Text level={2} mb=".6rem">
                {" "}
                Thursday 5:30pm PST: 1 hour QnA
              </Text>
              <Text level={2} mb="3.6rem">
                {" "}
                You’ll have access to our support team if you get stuck or want
                to solidify your understanding.
              </Text>
              <Heading as="h4" level={3} color="amethyst">
                How do I participate in Sonora Prep Remotely?
              </Heading>
              <Text level={2} mb="3.6rem">
                We use several programs that work in harmony to provide a
                fantastic remote classroom experience. When you register to join
                us, you will receive a detailed welcome email with all of the
                logistical information as well as the technical requirements for
                your computer and what apps you need to have installed.
              </Text>
              <Heading as="h4" level={3} color="amethyst">
                How much time will I need to practice outside of class?
              </Heading>
              <Text level={2} mb="3.6rem">
                We recommend at least 1 hour per day of focused practice to absorb this material.
              </Text>
              <Heading as="h4" level={3} color="amethyst">
                Can I transfer or re-take Sonora Prep?
              </Heading>
              <Text level={2} mb="1.6rem">
                Sonora Prep students are allowed 1 free transfer and 1 free
                re-take up to 6 months after their first attempt
              </Text>
              <Text level={2} mb="1.6rem">
                <strong>Transfer:</strong> Transfer to a different Sonora Prep
                session, requested no later than one week after class begins.{" "}
              </Text>
              <Text level={2} mb="3.6rem">
                <strong>Re-take:</strong> Transfer to a different Sonora Prep
                session, requested after the deferral deadline. This includes
                students who finish prep, but would like a second exposure
                before proceeding into the core program.
              </Text>
            </Box>
          </Width>
          <Box textAlign="center">
            <Text level={2} mb="1rem" mt="2em">
              <strong>
                <em>More Questions?</em>
              </strong>
            </Text>
            <Text level={2} fontSize="14px">
              Schedule a call to speak with an admissions counselor
            </Text>
            <CTA to="/prep-admissions" variation="primary" mt="1.6rem">
              Speak with Admissions
            </CTA>
          </Box>
        </Box>
      </Width>
    </Layout>
  )
}
